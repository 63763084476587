<template>
  <div class='training_programs_wrap'>
    <div class="padding_box custom_bg_white hover_animat">
          <div class="data_table_box">
                <!-- <h2 class="data_table_title">账号列表</h2> -->
                <div class="data_table_top">
                    <div class="data_table_top_left">
                        <div>
                            <Button  icon="md-add" type="success" @click="createData.show=true;">新增</Button>
                            <Button  icon="md-trash" type="error" @click="deleteFn">删除</Button>
                        </div>
                    </div>
                    <div class="data_table_top_right">
                        <div class="data_table_top_right_block">
                            <Input clearable style="width:200px" v-model="searchData.name" placeholder="请输入项目名称" />
                        </div>
                        <div class="data_table_top_right_block">
                            <Select clearable style="width:200px" v-model="searchData.status" placeholder="请选择项目状态">
                                <Option v-for="item in statusList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                        </div>
                        <div class="data_table_top_right_block">
                            <Button  icon="md-search" type="primary" @click="searchFn">搜索</Button>
                        </div>
                    </div>
                </div>
                <div class="table_scroll_wrap">
                    <Table style="min-width:1200px" :loading="tableLoading" stripe :columns="tableColumn" :data="tableData"   @on-selection-change="checkAllGroupChange">
                        <template slot-scope="{row}" slot="operation">
                            <!-- <Button style="margin-right:5px;" type="primary"   @click="showQRcodeFn(row)">二维码</Button> -->
                            <Dropdown style="margin-right:5px;" transfer  @on-click="showQRcodeFn($event,row)">
                                <Button type="primary" >
                                    二维码
                                    <Icon type="ios-arrow-down"></Icon>
                                </Button>
                                <DropdownMenu slot="list">
                                    <DropdownItem name="detail">课程详情</DropdownItem>
                                    <DropdownItem name="signIn">签到</DropdownItem>
                                    <DropdownItem name="signOut">签退</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Button  type="warning"   @click="showModifyFn(row)">修改</Button>
                        </template>
                        <template slot-scope="{row}" slot="apply_detail">
                            <div class="jump_to_link" href="javascript:;" @click="toApplyDetailFn(row.id)">查看</div>
                            <!-- <Button type="info" shape="circle" @click="toApplyDetailFn(row.id)">查看</Button> -->
                        </template>
                        <template slot-scope="{row,index}" slot="status">
                            <Tag v-if="row.status==='未开始'" color="primary">未开始</Tag>
                            <Tag v-if="row.status==='进行中'" color="success">进行中</Tag>
                            <Tag v-if="row.status==='已结束'" color="error">已结束</Tag>
                        </template>
                        
                    </Table>
                </div>
                <div class="pagination_wrap">
                    <div class="pagination_wrap">
                        <Page transfer show-elevator show-sizer show-total  :total="totalCount" @on-change="changePageFn" @on-page-size-change="changePageSizeFn" :current="currentPage" :page-size="pageSize" :page-size-opts='pageSizeOptions'/>
                    </div>
                </div>
          </div>
      </div>

        <Modal  title="新增培训项目" v-model="createData.show" :mask-closable="false" width="700" @on-cancel="cancelCreateFn">
            <div class="modal_scroll_wrap">
                <div class="edit_wrap">
                  <div class="upload_cover_wrap">
                    <div class="upload_cover_box">
                        <div class="upload_cover_priview">
                            <img v-show="createData.data.cover" :src="createData.data.cover" alt="">
                        </div>
                        <div class="upload_cover_button">
                            <div class="upload_btn">
                                <input ref="createCoverInput" type="file" accept="image/*" class="upload_input" @change="uploadImgFn($event,'createData')">
                                <span class="upload_span">{{createData.data.cover ? '重新上传' : '上传封面'}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="text-align:left;margin-top:10px">建议尺寸：400*180</div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>项目名称</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input v-model="createData.data.name"  style="width: 100%" @on-blur="handleBlurFn('createData','name')"/>
                          <span :class="createData.warning.name ? 'warning' : ''">{{createData.text.name}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>培训地点</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input v-model="createData.data.address"  style="width: 100%" @on-blur="handleBlurFn('createData','address')"/>
                          <span :class="createData.warning.address ? 'warning' : ''">{{createData.text.address}}</span>
                      </div>
                  </div>
                 
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>培训起止时间</h4>
                      </div>
                      <div class="edit_item_right">
                          <DatePicker transfer style="width: 100%" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" :options="dateOptions" v-model="createData.data.timeRange" @on-change="changeTimeFn($event,'createData','timeRange')" placeholder="培训起止时间"></DatePicker>
                          <span :class="createData.warning.timeRange ? 'warning' : ''">{{createData.text.timeRange}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>签到起止时间</h4>
                      </div>
                      <div class="edit_item_right">
                          <DatePicker transfer style="width: 100%" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" :options="dateOptions" v-model="createData.data.signInTimeRange" @on-change="changeTimeFn($event,'createData','signInTimeRange')" placeholder="签到起止时间"></DatePicker>
                          <span :class="createData.warning.signInTimeRange ? 'warning' : ''">{{createData.text.signInTimeRange}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>报名起止时间</h4>
                      </div>
                      <div class="edit_item_right">
                          <DatePicker transfer style="width: 100%" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" :options="dateOptions" v-model="createData.data.applyTimeRange" @on-change="changeTimeFn($event,'createData','signOutTimeRange')" placeholder="报名起止时间"></DatePicker>
                          <span :class="createData.warning.applyTimeRange ? 'warning' : ''">{{createData.text.applyTimeRange}}</span>
                      </div>
                  </div>
                  
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>最大可报人数</h4>
                      </div>
                      <div class="edit_item_right">
                          <InputNumber  :min="1" v-model="createData.data.maxCount" @on-blur="handleBlurFn('createData','maxCount')"></InputNumber>
                          <span :class="createData.warning.maxCount ? 'warning' : ''">{{createData.text.maxCount}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>项目介绍</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input type="textarea" v-model="createData.data.intro"  style="width: 100%"/>
                          <span :class="createData.warning.intro ? 'warning' : ''">{{createData.text.intro}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>考试介绍</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input type="textarea" v-model="createData.data.examinationIntro"  style="width: 100%"/>
                          <span :class="createData.warning.examinationIntro ? 'warning' : ''">{{createData.text.examinationIntro}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>考试链接</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input v-model="createData.data.examinationLink"  style="width: 100%"/>
                          <span :class="createData.warning.examinationLink ? 'warning' : ''">{{createData.text.examinationLink}}</span>
                      </div>
                  </div>
                  
                </div>
            </div>
            <div slot="footer">
                <Button type="primary"  @click="createFn">确定新增</Button>
            </div>
        </Modal>
        <Drawer  width="700" title="修改培训项目" :closable="true" :mask-closable="false" v-model="modifyData.show" @on-close="cancelModifyFn">
            <div class="drawer_scroll_wrap">
                <div class="edit_wrap">
                  <div class="upload_cover_wrap">
                    <div class="upload_cover_box">
                        <div class="upload_cover_priview">
                            <img v-show="modifyData.data.cover" :src="modifyData.data.cover" alt="">
                        </div>
                        <div class="upload_cover_button">
                            <div class="upload_btn">
                                <input ref="modifyCoverInput" type="file" accept="image/*" class="upload_input" @change="uploadImgFn($event,'modifyData')">
                                <span class="upload_span">{{modifyData.data.cover ? '重新上传' : '上传封面'}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="text-align:left;margin-top:10px">建议尺寸：400*180</div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>项目名称</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input v-model="modifyData.data.name"  style="width: 100%" @on-blur="handleBlurFn('modifyData','name')"/>
                          <span :class="modifyData.warning.name ? 'warning' : ''">{{modifyData.text.name}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>培训地点</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input v-model="modifyData.data.address"  style="width: 100%" @on-blur="handleBlurFn('modifyData','address')"/>
                          <span :class="modifyData.warning.address ? 'warning' : ''">{{modifyData.text.address}}</span>
                      </div>
                  </div>
                 
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>培训起止时间</h4>
                      </div>
                      <div class="edit_item_right">
                          <DatePicker transfer style="width: 100%" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" :options="dateOptions" v-model="modifyData.data.timeRange" @on-change="changeTimeFn($event,'modifyData','timeRange')" placeholder="培训起止时间"></DatePicker>
                          <span :class="modifyData.warning.timeRange ? 'warning' : ''">{{modifyData.text.timeRange}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>签到起止时间</h4>
                      </div>
                      <div class="edit_item_right">
                          <DatePicker transfer style="width: 100%" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" :options="dateOptions" v-model="modifyData.data.signInTimeRange" @on-change="changeTimeFn($event,'modifyData','signInTimeRange')" placeholder="签到起止时间"></DatePicker>
                          <span :class="modifyData.warning.signInTimeRange ? 'warning' : ''">{{modifyData.text.signInTimeRange}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>报名起止时间</h4>
                      </div>
                      <div class="edit_item_right">
                          <DatePicker transfer style="width: 100%" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" :options="dateOptions" v-model="modifyData.data.applyTimeRange" @on-change="changeTimeFn($event,'modifyData','applyTimeRange')" placeholder="报名起止时间"></DatePicker>
                          <span :class="modifyData.warning.applyTimeRange ? 'warning' : ''">{{modifyData.text.applyTimeRange}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>最大可报人数</h4>
                      </div>
                      <div class="edit_item_right">
                          <InputNumber  :min="1" v-model="modifyData.data.maxCount"></InputNumber>
                          <span :class="modifyData.warning.maxCount ? 'warning' : ''">{{modifyData.text.maxCount}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>项目介绍</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input type="textarea" v-model="modifyData.data.intro"  style="width: 100%"/>
                          <span :class="modifyData.warning.intro ? 'warning' : ''">{{modifyData.text.intro}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>考试介绍</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input type="textarea" v-model="modifyData.data.examinationIntro"  style="width: 100%"/>
                          <span :class="modifyData.warning.examinationIntro ? 'warning' : ''">{{modifyData.text.examinationIntro}}</span>
                      </div>
                  </div>
                  <div class="edit_item">
                      <div class="edit_item_left">
                          <h4>考试链接</h4>
                      </div>
                      <div class="edit_item_right">
                          <Input v-model="modifyData.data.examinationLink"  style="width: 100%"/>
                          <span :class="modifyData.warning.examinationLink ? 'warning' : ''">{{modifyData.text.examinationLink}}</span>
                      </div>
                  </div>
                </div>
            </div>
            <div class="drawer_submit_wrap">
                <Button type="primary"  @click="modifyFn">立即修改</Button>
            </div>
        </Drawer>

        <Modal v-model="statusModal.show" title="" :footer-hide="true" :mask-closable="false" :closable="false" width="360">
            <div style="text-align:center;padding:20px 0">
                <Spin fix v-if="!statusModal.showSuccess&&!statusModal.showError">
                    <Icon type="ios-loading" size=18 class="spin-icon-rotate"></Icon>
                    <div>{{statusModal.text}}</div>
                </Spin>
                <Spin fix v-if="statusModal.showSuccess" style="color:#00ad19">
                    <Icon type="ios-checkmark-circle" size=18 />
                    <div>{{statusModal.successText}}</div>
                </Spin>
                <Spin fix v-if="statusModal.showError" style="color:#f72b2b">
                    <Icon type="ios-close-circle" size=18 />
                    <div>{{statusModal.errorText}}</div>
                </Spin>
            </div>
        </Modal>

        <Modal width="500px" v-model="showQRcode.show" :mask-closable="false" >
            <div slot="header"></div>
            <div :class="'QRcode_box '+showQRcode.type">
                <img v-if="showQRcode.type==='detail'" :src="showQRcode.data.detail" alt="">
                <img v-if="showQRcode.type==='signIn'" :src="showQRcode.data.signIn" alt="">
                <img v-if="showQRcode.type==='signOut'" :src="showQRcode.data.signOut" alt="">
            </div>

            <div slot="footer" style="text-align:center">
                <span v-if="showQRcode.type==='detail'">{{showQRcode.data.detailText}}</span>
                <span v-if="showQRcode.type==='signIn'">{{showQRcode.data.signInText}}</span>
                <span v-if="showQRcode.type==='signOut'">{{showQRcode.data.signOutText}}</span>
            </div>
        </Modal>
  </div>
</template>

<script>
import Vue from "vue"
import { Table ,Tag ,Icon,Page,Row,Col,Input,Button,DatePicker,Select,Option,Drawer,Spin,InputNumber,Dropdown,DropdownMenu,DropdownItem} from 'view-design';
Vue.component('Table', Table);
Vue.component('Tag', Tag);
Vue.component('Icon', Icon);
Vue.component('Page', Page);
Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Input', Input);
Vue.component('Button', Button);
Vue.component('DatePicker', DatePicker);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Drawer', Drawer);
Vue.component('Spin', Spin);
Vue.component('InputNumber', InputNumber);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
import NET_PORT from "../../api/port.js"
var moment = require('moment');
export default {
  name:'training_programs',
  components:{},
  props:{},
  data(){
    return {
      person:this.$store.state.user,
      currentModifyId:'',
      firstEnter:true,
      showQRcode:{
        show:false,
        type:'detail',
        data:{
            detail:'',
            signIn:'',
            signOut:'',
            detailText:'',
            signInText:'',
            signOutText:'',
        }
      },
      statusModal:{
          show:false,
          showSuccess:false,
          showError:false,
          text:'正在上传',
          successText:'上传成功！',
          errorText:'上传失败！',
      },
      totalCount: 0,
      pageSize: 10,
      currentPage: 1,
      pageSizeOptions:[10,20,50,100],
      selection:[],
      tableLoading:false,

      dateOptions:{
        //   disabledDate (date) {
        //       return date && date.valueOf() < Date.now() - 86400000;
        //   }
      },


      statusList: [
            {
                value: '未开始',
                label: '未开始'
            },
            {
                value: '进行中',
                label: '进行中'
            },
            {
                value: '已结束',
                label: '已结束'
            },
          
        ],

      searchData:{
        name:'',
        status:'',
      },

      createData:{
        show:false,
        data:{
          cover:'',
          name:'',
          address:'',
          intro:'',
          examinationIntro:'',
          examinationLink:'',
          maxCount:1,
          timeRange:null,
          applyTimeRange:null,
          signInTimeRange:null,
        },
        verify:{
          name:false,
          address:false,
          cover:true,
          intro:true,
          examinationIntro:true,
          examinationLink:true,
          timeRange:false,
          applyTimeRange:false,
          signInTimeRange:false,
          maxCount:false,
        },
        text:{
           name:'',
           address:'',
          cover:'',
          intro:'',
          examinationIntro:'',
          examinationLink:'',
          timeRange:'',
          applyTimeRange:'',
          signInTimeRange:'',
          maxCount:'',
        },
        warning:{
          name:false,
          address:false,
          cover:false,
          intro:false,
          examinationIntro:false,
          examinationLink:false,
          timeRange:false,
          applyTimeRange:false,
          signInTimeRange:false,
          maxCount:false,
        },
      },
      modifyData:{
        show:false,
        data:{
          cover:'',
          name:'',
          address:'',
          intro:'',
          examinationIntro:'',
          examinationLink:'',
          timeRange:null,
          applyTimeRange:null,
          signInTimeRange:null,
          maxCount:1,
        },
        verify:{
          name:false,
          address:false,
          cover:true,
          intro:true,
          examinationIntro:true,
          examinationLink:true,
          timeRange:false,
          applyTimeRange:false,
          signInTimeRange:false,
          maxCount:false,
        },
        text:{
          name:'',
          address:'',
          cover:'',
          intro:'',
          examinationIntro:'',
          examinationLink:'',
          timeRange:'',
          applyTimeRange:'',
          signInTimeRange:'',
          maxCount:'',
        },
        warning:{
          name:false,
          address:false,
          cover:false,
          intro:false,
          examinationIntro:false,
          examinationLink:false,
          timeRange:false,
          applyTimeRange:false,
          signInTimeRange:false,
          maxCount:false,
        },
      },
      tableColumn:[
            {
                type: 'selection',
                width: 60,
                align: 'center'
            },
            {
                title: '序号',
                key: 'no',
                width:80,
            },
           
            {
                title: '项目名称',
                key: 'name',
            },
            {
                title: '培训地点',
                key: 'address',
            },

            {
                title: '培训起止时间',
                key: 'timeRange',
            },
           
            {
                title: '签到起止时间',
                key: 'signInTimeRange',
            },
            {
                title: '报名起止时间',
                key: 'applyTimeRange',
            },
            {
                title: '最大可报人数',
                key: 'maxCount',
            },

            {
                title: '报名人数',
                key: 'applyCount',
            },
            
            {
                title: '状态',
                key: 'status',
                slot:'status',
            },
            {
                title: '报名详情',
                key: 'apply_detail',
                slot:'apply_detail',
            },
           
            {
                title: '操作',
                key: 'operation',
                slot: 'operation',
                width:250,
                align:'center',
            },
      ],
      tableData:[],

    }
  },
  watch:{},
  computed:{},
  methods:{
      uploadImgFn(e,type){
          if(e.target.files.length){
              let obj=e.target.files[0];
              let fileFormat=obj.name.substring(obj.name.lastIndexOf(".")+1).toLowerCase();
              let formatArr=['jpg','png','jpeg','gif'];
              if(formatArr.indexOf(fileFormat)<0){
                  this.$Message.error('请上传受支持的图片格式！');
              }else{
                  this[type].data.imgFile=obj;
                  let reader = new FileReader();
                  reader.readAsDataURL(obj);
                  reader.onload = (e)=>{
                      this[type].data.cover=e.target.result;
                  }
              }
          }
      },
     deleteFn(){
        if(!this.selection.length){
            this.$Message.warning({
                title:'提示！',
                content:'请至少选择一项！'
            })
        }else{
             this.$Modal.confirm({
                title: '删除确认！',
                content: '<h3 style="color:#ed4014">您确定要删除这'+this.selection.length+'条数据吗？</h3>',
                onOk: () => {
                    // 显示出正在上传
                    this.statusModal.show=true;
                    this.statusModal.text='正在删除';
                    this.statusModal.successText='删除成功！';
                    this.statusModal.errorText='删除失败！';
                   this.certainDeleteFn();
                }
            });
        }
    },

    

    certainDeleteFn(){
        let bodyData=[]
        this.selection.forEach((item,index)=>{
            bodyData.push({
                projectID:item.id,
            })
        })

        this.$axios.post(NET_PORT.programsDelete,bodyData)
        .then((res)=>{
            
            if(res.data.code===200){
                this.successFn();
                this.tableData=[];
                this.totalCount=0
                this.currentPage=1
                this.getDataFn();
            }else{
                this.$Message.error({
                        content:res.data.msg,
                        duration:2,
                })
                this.errorFn();
            }
        })
        .catch(()=>{
            this.errorFn();
            this.$Message.error({
                    content:"网络错误！",
                    duration:2,
            })
        })
      
    },
     
    checkAllGroupChange(selection){
        this.selection=selection;
    },
    changePageFn(page){
        this.currentPage=page;
        this.getDataFn();
    },
    changePageSizeFn(size){
      this.currentPage=1;
      this.pageSize=size;
      this.getDataFn();
    },

    searchFn(){
        this.currentPage=1;
        this.getDataFn();
    },

    changeTimeFn(arr,parent,type){
        this.handleBlurFn(parent,type)
    },

    toApplyDetailFn(id){
        this.$router.push('/apply_detail?id='+id)
    },

    showQRcodeFn(e,obj){
        this.showQRcode.data={
            detail:obj.detailQRcode,
            signIn:obj.signInQRcode,
            signOut:obj.signOutQRcode,
            detailText:'',
            signInText:'',
            signOutText:'',
        }
        this.showQRcode.type=e;
        this.showQRcode.show=true;
        
    },

    paramsStrFn(params){
        let paramsStr = '';
        for(let key in params){
            paramsStr += key + '=' + params[key] + '&'
        }
        paramsStr = paramsStr.slice(0,-1);
        return paramsStr
    },

    successFn(){
        this.statusModal.showSuccess=true;
        setTimeout(()=>{
            this.statusModal.show=false;
            setTimeout(()=>{
                this.statusModal.showSuccess=false;
                this.statusModal.showError=false;
            },500)
        },1000)
    },

    errorFn(){
        this.statusModal.showError=true;
        setTimeout(()=>{
            this.statusModal.show=false;
            setTimeout(()=>{
                this.statusModal.showSuccess=false;
                this.statusModal.showError=false;
            },500)
        },1000)
    },

    cancelCreateFn(){
        this.$refs.createCoverInput.value='';
        this.createData={
            show:false,
            data:{
                cover:'',
                name:'',
                address:'',
                intro:'',
                examinationIntro:'',
                examinationLink:'',
                maxCount:1,
                timeRange:[],
                applyTimeRange:[],
                signInTimeRange:[],
            },
            verify:{
                name:false,
                address:false,
                cover:true,
                intro:true,
                examinationIntro:true,
                examinationLink:true,
                timeRange:false,
                applyTimeRange:false,
                signInTimeRange:false,
                maxCount:false,
            },
            text:{
                name:'',
                address:'',
                cover:'',
                intro:'',
                examinationIntro:'',
                examinationLink:'',
                timeRange:'',
                applyTimeRange:'',
                signInTimeRange:'',
                maxCount:'',
            },
            warning:{
                name:false,
                address:false,
                cover:false,
                intro:false,
                examinationIntro:false,
                examinationLink:false,
                timeRange:false,
                applyTimeRange:false,
                signInTimeRange:false,
                maxCount:false,
            },
        }

    },

    createFn(){
        this.handleBlurFn('createData','name')
        this.handleBlurFn('createData','address')
        this.handleBlurFn('createData','timeRange')
        this.handleBlurFn('createData','applyTimeRange')
        this.handleBlurFn('createData','signInTimeRange')
        this.handleBlurFn('createData','maxCount')
        var verifyErrorNum=0;
        for(var k in this.createData.verify){
            if(!this.createData.verify[k]){
                verifyErrorNum++;
            }
        }

        if(!verifyErrorNum){
            this.statusModal.show=true;
            this.statusModal.text='正在新增';
            this.statusModal.successText='新增成功！';
            this.statusModal.errorText='新增失败！';
            let timeRangeFormat=moment(this.createData.data.timeRange[0]).format('YYYY-MM-DD HH:mm:ss')+' - '+moment(this.createData.data.timeRange[1]).format('YYYY-MM-DD HH:mm:ss')
            let applyTimeRangeFormat=moment(this.createData.data.applyTimeRange[0]).format('YYYY-MM-DD HH:mm:ss')+' - '+moment(this.createData.data.applyTimeRange[1]).format('YYYY-MM-DD HH:mm:ss')
            let signInTimeRangeFormat=moment(this.createData.data.signInTimeRange[0]).format('YYYY-MM-DD HH:mm:ss')+' - '+moment(this.createData.data.signInTimeRange[1]).format('YYYY-MM-DD HH:mm:ss')
            let bodyData={
                projectName:this.createData.data.name,
                location:this.createData.data.address,
                trainTime:timeRangeFormat,
                applyTime:applyTimeRangeFormat,
                signTime:signInTimeRangeFormat,
                maxApplyPerson:this.createData.data.maxCount,
                projectPicture:this.createData.data.cover,
                note:this.createData.data.intro,
                examinationDetail:this.createData.data.examinationIntro,
                examinationUrl:this.createData.data.examinationLink,
            }

            this.$axios.post(NET_PORT.programsAdd,bodyData)
            .then((res)=>{
                if(res.data.code===200){
                   this.createData.show=false;
                    this.cancelCreateFn();
                    this.successFn();
                    this.tableData=[];
                    this.totalCount=0
                    this.currentPage=1
                    this.getDataFn();
                }else{
                    this.$Message.error({
                            content:res.data.msg,
                            duration:2,
                    })
                    this.errorFn();
                }
            })
            .catch(()=>{
                this.errorFn();
                this.$Message.error({
                        content:"网络错误！",
                        duration:2,
                })
            })
        }

    },

    showModifyFn(obj){
      this.modifyData.show=true;
      this.currentModifyId=obj.id;
      let timeRange=[obj.timeRange.split(' - ')[0],obj.timeRange.split(' - ')[1]]
      let applyTimeRange=[obj.applyTimeRange.split(' - ')[0],obj.applyTimeRange.split(' - ')[1]]
      let signInTimeRange=[obj.signInTimeRange.split(' - ')[0],obj.signInTimeRange.split(' - ')[1]]
      this.modifyData.data={
        cover:obj.cover,
        name:obj.name,
        address:obj.address,
        intro:obj.intro,
        examinationIntro:obj.examinationIntro,
        examinationLink:obj.examinationLink,
        timeRange:timeRange,
        applyTimeRange:applyTimeRange,
        signInTimeRange:signInTimeRange,
        maxCount:obj.maxCount,
      }
    },

    cancelModifyFn(){
        this.$refs.modifyCoverInput.value='';
        this.currentModifyId='';
      this.modifyData={
        show:false,
        data:{
          cover:'',
          name:'',
          address:'',
          intro:'',
          examinationIntro:'',
          examinationLink:'',
          timeRange:[],
          applyTimeRange:[],
          signInTimeRange:[],
          maxCount:1,
        },
        verify:{
          name:false,
          address:false,
          cover:true,
          intro:true,
          examinationIntro:true,
          examinationLink:true,
          timeRange:false,
          applyTimeRange:false,
          signInTimeRange:false,
          maxCount:false,
        },
        text:{
          name:'',
          address:'',
          cover:'',
          intro:'',
          examinationIntro:'',
          examinationLink:'',
          timeRange:'',
          applyTimeRange:'',
          signInTimeRange:'',
          maxCount:'',
        },
        warning:{
          name:false,
          address:false,
          cover:false,
          intro:false,
          examinationIntro:false,
          examinationLink:false,
          timeRange:false,
          applyTimeRange:false,
          signInTimeRange:false,
          maxCount:false,
        },
      }
    },

    modifyFn(){
        this.handleBlurFn('modifyData','name')
        this.handleBlurFn('modifyData','address')
        this.handleBlurFn('modifyData','timeRange')
        this.handleBlurFn('modifyData','applyTimeRange')
        this.handleBlurFn('modifyData','signInTimeRange')
        this.handleBlurFn('modifyData','maxCount')
        
        var verifyErrorNum=0;
        for(var k in this.modifyData.verify){
            if(!this.modifyData.verify[k]){
                verifyErrorNum++;
            }
        }
        // console.log(this.modifyData)
         if(!verifyErrorNum){
            this.statusModal.show=true;
            this.statusModal.text='正在修改';
            this.statusModal.successText='修改成功！';
            this.statusModal.errorText='修改失败！';
            let timeRangeFormat=moment(this.modifyData.data.timeRange[0]).format('YYYY-MM-DD HH:mm:ss')+' - '+moment(this.modifyData.data.timeRange[1]).format('YYYY-MM-DD HH:mm:ss')
            let applyTimeRangeFormat=moment(this.modifyData.data.applyTimeRange[0]).format('YYYY-MM-DD HH:mm:ss')+' - '+moment(this.modifyData.data.applyTimeRange[1]).format('YYYY-MM-DD HH:mm:ss')
            let signInTimeRangeFormat=moment(this.modifyData.data.signInTimeRange[0]).format('YYYY-MM-DD HH:mm:ss')+' - '+moment(this.modifyData.data.signInTimeRange[1]).format('YYYY-MM-DD HH:mm:ss')
            let bodyData={
                projectID:this.currentModifyId,
                projectName:this.modifyData.data.name,
                location:this.modifyData.data.address,
                trainTime:timeRangeFormat,
                applyTime:applyTimeRangeFormat,
                signTime:signInTimeRangeFormat,
                maxApplyPerson:this.modifyData.data.maxCount,
                projectPicture:this.modifyData.data.cover,
                note:this.modifyData.data.intro,
                examinationDetail:this.modifyData.data.examinationIntro,
                examinationUrl:this.modifyData.data.examinationLink,
            }

            this.$axios.post(NET_PORT.programsModify,bodyData)
            .then((res)=>{
                if(res.data.code===200){
                   this.modifyData.show=false;
                    this.cancelModifyFn();
                    this.successFn();
                    this.tableData=[];
                    this.totalCount=0
                    this.currentPage=1
                    this.getDataFn();
                }else{
                    this.$Message.error({
                            content:res.data.msg,
                            duration:2,
                    })
                    this.errorFn();
                }
            })
            .catch(()=>{
                this.errorFn();
                this.$Message.error({
                        content:"网络错误！",
                        duration:2,
                })
            })
        }
    },

    handleBlurFn(type,key){
        let obj=type;
        let name=key;
        let text="";
        let warning=false;
        if(key==='name'){
            if(this[obj].data.name.trim()===''){
            
                text='培训项目名不能为空！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                text=''
                warning=false;
                this[obj].verify[name]=true
            }

        }else if(key==='address'){
            if(this[obj].data.address.trim()===''){
            
                text='培训地点不能为空！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                text=''
                warning=false;
                this[obj].verify[name]=true
            }

        }else if(key==='timeRange'){
            if(this[obj].data.timeRange===null||this[obj].data.timeRange[0]===''){
            
                text='请选择培训起止时间！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                text=''
                warning=false;
                this[obj].verify[name]=true
            }

        }else if(key==='applyTimeRange'){
            if(this[obj].data.applyTimeRange===null||this[obj].data.applyTimeRange[0]===''){
            
                text='请选择报名起止时间！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                text=''
                warning=false;
                this[obj].verify[name]=true
            }

        }else if(key==='signInTimeRange'){
            if(this[obj].data.signInTimeRange===null||this[obj].data.signInTimeRange[0]===''){
            
                text='请选择签到起止时间！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                text=''
                warning=false;
                this[obj].verify[name]=true
            }

        }else if(key==='maxCount'){
            if(this[obj].data.maxCount===null||this[obj].data.maxCount===0){
            
                text='请输入正确的人员数量！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                text=''
                warning=false;
                this[obj].verify[name]=true
            }

        }

        this[obj].text[name]=text;
        if(warning){
            this[obj].warning[name]=true
        }else{
            this[obj].warning[name]=false
        }
    },

    getDataFn(){
        // this.tableData=[
        //     {
        //         no:1,
        //         id:1,
        //         name:'育婴师考试讲解',
        //         address:'华西附二院会议室',
        //         timeRange:'2022-09-23 15:30:00 - 2022-09-28 03:05:05',
        //         signInTimeRange:'2022-09-23 15:30:00 - 2022-09-28 03:05:05',
        //         applyTimeRange:'2022-09-23 15:30:00 - 2022-09-28 03:05:05',
        //         maxCount:'300',
        //         applyCount:'185',
        //         status:'未开始',
        //     }
        // ]

        this.tableLoading=true;
        this.selection=[];
       
        let bodyData={
            projectID:'',
            projectName:this.searchData.name,
            location:'',
            status:this.searchData.status,
        }

        let urlData={
            pageIndex:this.currentPage,
            pageSize:this.pageSize,
        }

        let paramsStr='?'+this.paramsStrFn(urlData)
        this.$axios.post(NET_PORT.programsList+paramsStr,bodyData)
        .then((res)=>{
            if(res.data.code===200){
                this.tableData=[];
                for(let i=0;i<res.data.data.length-1;i++){
                    this.tableData.push({
                        no:i+1,
                        id:res.data.data[i].projectID,
                        name:res.data.data[i].projectName,
                        address:res.data.data[i].location,
                        timeRange:res.data.data[i].trainTime,
                        signInTimeRange:res.data.data[i].signTime,
                        applyTimeRange:res.data.data[i].applyTime,
                        maxCount:res.data.data[i].maxApplyPerson,
                        applyCount:res.data.data[i].applyNumber,
                        status:res.data.data[i].status,
                        cover:res.data.data[i].projectPicture,
                        detailQRcode:res.data.data[i].applyQRcode,
                        signInQRcode:res.data.data[i].signQRcode,
                        signOutQRcode:res.data.data[i].backQRcode,
                        intro:res.data.data[i].note,
                        examinationIntro:res.data.data[i].examinationDetail,
                        examinationLink:res.data.data[i].examinationUrl,
                    })
                }
                this.totalCount=Number(res.data.data[res.data.data.length-1].total);
                this.tableLoading=false;
                if(this.firstEnter){
                    this.firstEnter=false;
                    this.$Loading.finish();
                }
            }else{
                this.$Message.error({
                    content:res.data.msg,
                    duration:2,
                })
            }
        })
        .catch(()=>{
            this.$Message.error({
                    content:"网络错误！",
                    duration:2,
            })
        })

        // this.tableLoading=false;
        // this.$Loading.finish();
    },
  },
  created(){
    this.$Loading.start();
    this.getDataFn();
  },
  mounted(){}
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>